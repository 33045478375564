<script>
  /**
   * A component containing a button that when clicked, toggles the visibility of some content (passed with the default slot) by sliding it up/down.
   */
  export default {
    props: {
      /** Whether the accordion is currently expanded. */
      expanded: {
        type: Boolean,
        default: false,
      },
      /** The button’s inner classes if the `button` slot is not used. */
      buttonInnerClasses: {
        type: String,
        default: undefined,
      },
      /** What type of icon should be used for toggling expanded state. */
      icon: {
        type: String,
        default: 'chevron-down',
        validator: (value) => ['chevron-down', 'chevron-right', 'minus'].includes(value),
      },
      /** How the text button should be aligned. */
      textButtonAlignment: {
        type: String,
        default: 'justify-between',
        validator: (value) =>
          ['justify-between', 'justify-center', 'justify-start'].includes(value),
      },
    },
    data() {
      return {
        innerExpanded: null,
      }
    },
    computed: {
      buttonAria() {
        return {
          'aria-controls': `accordion-content-${this._uid}`,
          'aria-expanded': this.innerExpanded,
        }
      },
    },
    watch: {
      expanded: {
        immediate: true,
        handler() {
          this.innerExpanded = this.expanded
        },
      },
    },
    methods: {
      toggle() {
        this.innerExpanded = !this.innerExpanded
        /** Emitted when the accordion is expanded or collapsed. */
        this.$emit('toggle', this.innerExpanded)
      },
    },
  }
</script>

<template>
  <div>
    <!-- @slot Optional. The accordion’s button. Defaults to an uppercase text button that renders the `buttonLabel` slot and an arrow pointing up or down (depending on the expanded state). Provides three props: `expanded` (boolean), `aria` (object to bind to the button), and `toggle` (function to call when the button is clicked). -->
    <slot name="button" :expanded="innerExpanded" :aria="buttonAria" :toggle="toggle">
      <button
        type="button"
        class="group block w-full rounded-sm text-black"
        v-bind="buttonAria"
        @click="toggle"
      >
        <!-- for purgecss: justify-between justify-center justify-start -->
        <span
          :class="`flex ${textButtonAlignment} items-center ${
            buttonInnerClasses ? buttonInnerClasses : ''
          }`"
        >
          <span :class="`${textButtonAlignment === 'justify-between' ? 'flex-grow' : ''}`">
            <BaseHeading
              size="h6"
              tag="h3"
              class="overflow-hidden ellipsis"
              style="letter-spacing: 0.03em"
            >
              <!-- @slot The button’s label. Only if the `button` slot is not used. Should contain text. -->
              <slot name="buttonLabel" :expanded="innerExpanded" />
            </BaseHeading>
          </span>
          <BaseIcon
            v-if="icon.includes('chevron')"
            :label="innerExpanded ? 'Collapse' : 'Expand'"
            :size="3"
            :class="`ml-2 mr-1 flex-shrink-0 transform ${
              innerExpanded ? (icon === 'chevron-down' ? '-rotate-180' : 'rotate-90') : ''
            } transition duration-200`"
          >
            <IconChevronDownThick v-if="icon === 'chevron-down'" />
            <IconChevronRightThick v-else />
          </BaseIcon>
          <template v-else>
            <BaseIcon v-if="innerExpanded" label="Collapse" :size="5" class="text-black">
              <IconMinus />
            </BaseIcon>
            <BaseIcon v-else label="Expand" :size="5" class="text-black">
              <IconPlus />
            </BaseIcon>
          </template>
        </span>
      </button>
    </slot>
    <BaseSlideUpDown :id="`accordion-content-${_uid}`" :expanded="innerExpanded">
      <!-- @slot The accordion’s content. Can contain anything. -->
      <slot />
    </BaseSlideUpDown>
  </div>
</template>
